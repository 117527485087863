/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */

import { useEffect, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { getCurrentAuthenticatedUser, getCurrentSession } from '../../lib/authenticationApi';
import CognitoResult from '../../models/CognitoResult';
import { AppContext } from '../../containers/App';
import AppDocument from '../../models/AppDocument';
import getAppDocument from '../../lib/getAppDocument';
import { noAuthRequired } from '../../lib/appGlobals';

function FallbackRoute(): JSX.Element {
  const [isUserSession, setIsUserSession] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<CognitoUserInterface | undefined>({} as CognitoUserInterface);
  const { handleSetIsAuth } = useContext(AppContext);
  const [appData, setAppData] = useState<AppDocument>({} as AppDocument);

  useEffect(() => {
    const sendUserInfoGetAppData = (): void => {
      if (Object.keys(appData).length > 0 && handleSetIsAuth) {
        if (user) {
          handleSetIsAuth(true, user, appData);
        }
      }
    };
    const getContentData = async (): Promise<void> => {
      const appDocument = await getAppDocument();
      setAppData(appDocument);
      sendUserInfoGetAppData();
      setIsLoading(false);
    };
    const getUser = async (): Promise<void> => {
      await getCurrentAuthenticatedUser().then((userResult: CognitoResult) => {
        if (userResult.hasError === true) {
          setIsUserSession(false);
          setIsLoading(false);
        } else if (userResult.hasError === false) {
          setUser(userResult.data as CognitoUserInterface);
          setIsUserSession(true);
          getContentData();
        }
      });
    };
    const getUserSession = async (): Promise<boolean> => {
      if (isUserSession === false) {
        await getCurrentSession().then((userSession: CognitoResult) => {
          if (userSession.hasError === true) {
            setIsUserSession(false);
            setIsLoading(false);
          } else if (userSession.hasError === false) {
            getUser();
          }
        });
      } else if (isUserSession === true) {
        await getUser();
      }
      return isUserSession;
    };
    if (noAuthRequired === 'true') {
      setIsUserSession(true);
      getContentData();
    } else {
      getUserSession();
    }
  }, [setIsLoading]);

  return (
    <>
      {!isLoading && (
        <>
          {isUserSession && (
            <>
              <Redirect to="/home" />
            </>
          )}
          {!isUserSession && <Redirect to="/" />}
        </>
      )}
    </>
  );
}

export default FallbackRoute;
