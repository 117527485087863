import { useState, useEffect } from 'react';

import useSearchObject from '../../lib/useSearchObject';

import UrlLinkProps from '../../models/UrlLinkProps';
import TextProps from '../../models/TextProps';

import styles from './Footer.module.scss';

const Footer = (): JSX.Element => {
  const data = useSearchObject('key', 'root-footer-1', false);
  const [linkData, setLinkData] = useState<[UrlLinkProps] | undefined>(undefined);
  const [textData, setTextData] = useState<[TextProps] | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setLinkData(data.links.data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setTextData(data.texts.data);
    }
  }, [data]);

  return (
    <div className={styles.Footer}>
      <>
        <span>Copyright © {new Date().getFullYear()}</span>
        {textData && (
          <>
            {textData.map(({ key, name }) => (
              <span key={key}>
                <p className={styles.Link}>{name}</p>
              </span>
            ))}
          </>
        )}
        <span> | </span>
        {linkData && (
          <>
            {linkData.map(({ key, name, href }) => (
              <span key={key}>
                <a className={styles.Link} href={href}>
                  {name}
                </a>
              </span>
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default Footer;
